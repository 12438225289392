import './App.css';
import Mylink from './pages/mylink/Mylink';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { useState } from 'react';

function App() {

  const [color,setColor] = useState('black')

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Mylink color={color} />,
    }
  ]);

  return (
    createRoot(document.getElementById("root")).render(
      <RouterProvider router={router} />
    )
  );
}

export default App;
