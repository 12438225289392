import { Box, HStack, Image, Text } from "@chakra-ui/react";

const BoxArticle = () => {
    return (
        <Box
            bg={'#f6f6f6'} 
            mt="1rem"
            p={'0.5rem'} 
            borderRadius="1rem" 
            color={'black'} 
            className="boxHoverEffect"
            maxW={'40rem'}
            >
            <HStack zIndex={2}>
                <Image 
                    width={'6rem'}
                    height={'6rem'}
                    borderRadius={'1rem'}
                    src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos.jpg" />
                <Box>
                    <Text 
                        fontSize={'1rem'} 
                        fontWeight="bold"
                        textAlign={'left!important'}
                        >
                            Blog Title
                    </Text>
                    <Text 
                        fontSize={'0.8rem'} 
                        className={'description'}
                        fontWeight="400"
                        >
                            Throughout my life, I have developed this art of being able to communicate with anyone at any given time....
                    </Text>
                </Box>
            </HStack>
        </Box>
    )
}
export default BoxArticle;