import { Box,HStack,Image,Text } from "@chakra-ui/react";

const BoxText = ({label,link,src}) => {
    return (
        <a href={link} target="_blank">
            <Box 
                className="boxHoverEffect"
                color={'black'}
                maxW={'40rem'}
                
                bg={'#f6f6f6'}
                p="1rem"
                textAlign={'center'}
                borderRadius="0.5rem"
                fontWeight={'bold'}
                mt="1rem"
                >
                    <HStack zIndex={999}>
                        {
                            src && 
                            <Image height={'22px'} src={src}/>
                        }
                        <Text zIndex={2}>{label}</Text>
                    </HStack>
            </Box>
        </a>
    )
}

export default BoxText;