import { Box, Image } from "@chakra-ui/react";

const Photos = ({item,index,setOpen}) => {
    return (
        <Box onClick={()=>setOpen(true)} key={index} cursor={'pointer'}>
            <Image 
                height={"13rem"}
                width={"9rem"}
                maxW={"11rem"}
                objectFit="cover"
                borderRadius={'1rem'}
                src={item.src} />
        </Box>
    )
}

export default Photos;